/**
 * 资讯
 * @author qijiajun <qijiajun@freshfirst.cn>
 * @date   2017-07-25
 */

function detail(){
	window.location.href='/detail_info';
}

$(function(){
	$('.lookDetail').click(function(){
		detail();
	});

	/**
	 * 设置分页
	 * @author qijiajun <qijiajun@freshfirst.cn>
	 * @date   2017-07-25
	 */
	$('.M-box').pagination({
		coping: false,
		keepShowPN: true,
		count: 2,
		totalData: 60,
		showData: 6,
		activeCls: 'pages',
		prevContent: '<<',
		nextContent: '>>',
		callback:function(api){
			// api.setPageCount(20);//动态修改总页数为20页
		}
	});
})