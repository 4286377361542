
$(function(){
	var ulcont = $(".slibe-view-container ul");
	var quantity = $(".slibe-view-container >ul >li").length;
	var number   = 8;

	$(".slibe-view-container >ul >li").hover(
		function(){
			var content = $(this).data('content');
			var title   = $(this).data('title');
			$(".logo-text-title").html(title);
			$(".logo-text-main").html(content);
		}
	);

	$(".icons-left").mousedown(function(){
		number -= 1;
		if(number < 0){
			number = 13;
		}
		slibe_move(number);
	});

	$(".icons-right").mousedown(function(){
		number += 1;
		if(number >= 14){
			number = 0;
		}
		slibe_move(number);
	});

	$(".slibe-view-container >ul >li").mousedown(function(){
		number = $(this).data('num');
		slibe_move(number);
	});

	function slibe_move(number){
		left = (number - 2) * -235;
		$(".slibe-view-container >ul").css({"left": left + "px"});
		var content = $(".slibe-view-container >ul >li.logo-" + number).data('content');
		var title   = $(".slibe-view-container >ul >li.logo-" + number).data('title');
		$(".slibe-view-container >ul >li.active").removeClass('active');
		$(".slibe-view-container >ul >li.logo-" + number).addClass('active');
		// $(".logo-text-title").html(title);
		$(".logo-text-main").html(content);
	}


		//banner轮播
		/*向左的按钮*/
	$('.home-banner .left-btn ').on('click', function(){
		var $prev = $('.controller > li.active').prev();
		if($prev.length === 0){
			$prev = $('.controller > li').last();
		}
		$prev.trigger('click');
	});
		/*向右的按钮*/
	$(".home-banner .right-btn").click(function(){
		var $next = $('.controller > li.active').next();
		if($next.length === 0){
			$next = $('.controller > li').first();
		}
		$next.trigger('click');
	});

	}
);

$(function(){
	$(".foot-contact .foot-main ul li .hot").mouseover(function(){
		$(".foot-contact  .new_ask").show();
		$(".foot-contact .foot-main ul li .hot i").css("background","url(/assets/frontend/default/img/up.png)")
	}).mouseout(function(){
		$(".foot-contact  .new_ask").hide()
		$(".foot-contact .foot-main ul li .hot i").css("background","url(/assets/frontend/default/img/down.png)")
	})
	$(".foot-contact  .new_ask").mouseover(function(){
		$(this).show();
		$(".foot-contact .foot-main ul li .hot i").css("background","url(/assets/frontend/default/img/up.png)")
	}).mouseout(function(){
		$(this).hide();
		$(".foot-contact .foot-main ul li .hot i").css("background","url(/assets/frontend/default/img/down.png)")
	});
});
$(function(){
	$(" .header .ways .nav").click(function(){
		$(".header .ways  ul").css("display","block");
	});
});
$(function(){
	$(".footer .footer_top ul li a").click(function(){
		$(".footer .footer_top ul li a i").removeClass("down").addClass("up");
		$(".footer .footer_top ul li dl").css("display","block");
	});
});

