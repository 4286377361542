/**
 * 案例
 * @author qijiajun <qijiajun@freshfirst.cn>
 * @date   2017-07-25
 */
function cases(){
	window.location.href='/detail_eg';
}

function init(){
	let content = document.getElementById("exam");
	let picPath = '/assets/frontend/default/img/pic.png';
	let template = '<div class="all-content lookCases">' + 
		'<div>' + 
			'<img src="picPath">' + 
		'</div>' + 
		'<div class="c-title">' + 
			'<span>' + 'xx类型' + '</span>' + '|' + '<span>' + '公司名称' + '</span>' + 
		'</div>'+ 
		'<div class="title">' + 
			'<h3>' + '大标题' + '</h3>' +
			'这是正文内容这是正文内容这是正文内容这是正文内容这是正文内容这是正文内容' + 
		'</div>' + 
		'<div class="b-title">' + 
			'<span>' + '规模' + '</span>' + '<span>' + '痛点1' + '</span>' + '<span>' + '痛点2' + '</span>' + 
		'</div>' + 
	'</div>';
	content.innerHTML = template;
}

$(function(){
	$('.lookCases').click(function(){
		cases();
	});

	/**
	 * 设置分页
	 * @author qijiajun <qijiajun@freshfirst.cn>
	 * @date   2017-07-25
	 */
	$('.M-box1').pagination({
		coping: false,
		keepShowPN: true,
		count: 2,
		totalData: 60,
		showData: 6,
		activeCls: 'pages',
		prevContent: '<<',
		nextContent: '>>',
		callback:function(api){
			// api.setPageCount(20);//动态修改总页数为20页
		}
	},function(api){
		init();
	});
	// $('#exam').blur(function(){
	// init();
	// });
});