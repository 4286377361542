var Tab = function (selector, option) {
//                log('tab is initializing...');
    this.selector = selector || '.tab';

    if (typeof this.selector === 'string') {
        this.tabs = $(this.selector);
    }
    else {
        return this;
    }
//            console.log(this.tabs);

    if (this.tabs.length === 0) {
//                    error('Can not find tab container, please initialize tab by yourself.', '[' + this.selector + ']');
        return this;
    }

    var ret = this._init(this.tabs, option);
//                log('tab is initialized.');
    return ret;
};

Tab.prototype = {
    _init: function ($tabs, option) {
        var $this = this;
        if ($tabs.length > 1) {
            $tabs.each(function (index, el) {
                var $el = $(el);
                $this._init($el, option);
            });
            return $tabs;
        }
        var $headers = $tabs.children('.tab-hd');
        if ($headers.length === 0) {
//                        error('Can not find tab headers, please check your html', $tabs);
            return $this;
        }
        $headers.on('click', '.item', function () {
            var $item = $(this);
            var prevTab = $tabs.find('.item.active').removeClass('active').data('tab');
            var nextTab = $item.addClass('active').data('tab');
            $this.tabs.find('.tab-bd[data-tab=' + prevTab + ']').removeClass('active');

            $this.tabs.find('.tab-bd[data-tab=' + nextTab + ']').addClass('active');
//                    console.log($('.tab-bd[data-tab=' + prevTab + ']'));
//                    console.log($('.tab-bd[data-tab=' + nextTab + ']'));
            if (typeof option.callback === 'function') {
                option.callback($item);
            }
        });
        return $tabs;
    }
};/**
 * Created by Administrator on 2016/10/20.
 */
