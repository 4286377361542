
/**** 验证 ****/
function getValidCode(vali_btn, wait_time){
	if (vali_btn.html() == "重新发送验证码") {
		wait_time = 60;
	}
	if(wait_time===0){
		vali_btn.attr("disabled",false);
		vali_btn.html("重新发送验证码");
		vali_btn.addClass ("underline");
		return true;
	}else{
		vali_btn.html(wait_time + "秒后重新发送");
		wait_time--;
		setTimeout(function(){
			getValidCode(vali_btn, wait_time);
		},1000);
	}
}

function nameBlur(){
	if($("#r_name").val() === ''){
		$("#r_name").next('.tips').html("请输入您的姓名");
	}else if($("#r_name").val().length < 1){
		$("#r_name").next('.tips').html("请输入规范的姓名");
	}else{
		$("#r_name").next('.tips').html("");
	}
}

function company(){
	if($("#company").val() === ''){
		$("#company").next('.tips').html("请输入您的公司名称");
	}else if($("#company").val().length < 5){
		$("#company").next('.tips').html("请输入规范的公司名称");
	}else{
		$("#company").next('.tips').html("");
	}
}

function companyAddress(){
	if($("#c_address").val() === ''){
		$("#c_address").next('.tips').html("请输入您的公司地址");
	}else if($("#c_address").val().length < 5){
		$("#c_address").next('.tips').html("请输入正确的公司地址");
	}else{
		$("#c_address").next('.tips').html("");
	}
}

function mobileBlur(){
	var testMobile = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/;
	if(testMobile.test($("#mobile").val())){
		$("#mobile").next('.tips').html("");
	}else if($("#mobile").val() === ''){
		$("#mobile").next('.tips').html("请输入手机号码");
	}else{
		$("#mobile").next('.tips').html("请输入正确的手机号码");
	}
}

function codeBlur(){
	var testCode = /^[0-9]{6}$/;
	if(testCode.test($("#valiCode").val())){
		$("#valiCode").next().next('.tips').html("");
	}else if($("#valiCode").val() === ""){
		$("#valiCode").next().next('.tips').html("请输入验证码");
	}else{
		$("#valiCode").next().next('.tips').html("请输入正确的验证码");
	}
}
function applySubmit(){
	var testMobile = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/;
	var testCode = /^[0-9]{6}$/;
	var testPassword = /^[0-9_a-zA-Z]{6,12}$/;
	var $this = this;
	var info = location.search.substring(1).split('&');   //ID获取
	var infoIds = {};
	for(var x in info){
		infoIds[info[x].split('=')[0]]=info[x].split('=')[1];
	}
	//验证用户名
	if($("#r_name").val() === ''){
		$("#r_name").next('.tips').html("请输入您的姓名");
		$("#r_name").focus();
		return false;
	}else if($("#r_name").val().length < 1){
		$("#r_name").next('.tips').html("请输入规范的姓名");
		$("#r_name").focus();
		return false;
	}else{
		$("#r_name").next('.tips').html("");
	}
	//验证公司名称
	if($("#company").val() === ''){
		$("#company").next('.tips').html("请输入您的公司名称");
		$("#company").focus();
		return false;
	}else if($("#company").val().length < 5){
		$("#company").next('.tips').html("请输入规范的公司名称");
		$("#company").focus();
		return false;
	}else{
		$("#company").next('.tips').html("");
	}
	//验证公司地址
	if($("#c_address").val() === ''){
		$("#c_address").next('.tips').html("请输入您的公司地址");
		$("#c_address").focus();
		return false;
	}else if($("#c_address").val().length < 5){
		$("#c_address").next('.tips').html("请输入正确的公司地址");
		$("#c_address").focus();
		return false;
	}else{
		$("#c_address").next('.tips').html("");
	}
	//验证手机
	if($('#mobile').val()===""){
		$("#mobile").next('.tips').html("请输入手机号码");
		$("#mobile").focus();
		return false;
	}else if(!testMobile.test($("#mobile").val())){
		$("#mobile").next('.tips').html("请输入正确的手机号码");
		$("#mobile").focus();
		return false;
	}else{
		$("#mobile").next('.tips').html("");
	}
	console.log($('#mobile').val());
	//验证码
	if(!testCode.test($('#valiCode').val())){
		$("#valiCode").next().next('.tips').html("请输入正确的验证码");
		$("#valiCode").focus();
		return false;
	}else if($('#valiCode').val()===""){
		$("#valiCode").next().next('.tips').html("请输入验证码");
		$("#valiCode").focus();
		return false;
	}else{
		$("#valiCode").next('.tips').html("");
	}
	//请勾选服务协议
	if(!$("#agreement-agree").is(":checked")){
		$("#agreement-agree").next().next('.tips').html("请勾选服务协议").css('text-align','center');
		return false;
	}
	if(testMobile.test($("#mobile").val())){
		$.ajax({
			url: '/api/utility/apply',
			type: 'post',
			data:{
				action:'verify_mobile',
				mobile:$("#mobile").val(),
			}
		})
		.done(function(res){
			if(res.code === '-2'){
				//已注册  提示请直接登录
				$("#mobile").next('.tips').html("您的手机号码已被注册");
				return false;
			}else if(res.code === '0'){
				if(testCode.test($('#valiCode').val())){
					$.ajax({
						url:'/api/utility/apply',
						type: 'POST',
						dataType: "json",
						data:{
							action : 'do_apply',
							mobile : $('#mobile').val(),
							name : $('#company').val(),
							address : $('#c_address').val(),
							contact: $('#r_name').val(),
							vcode : $('#valiCode').val(),
							source:  infoIds.source,
						},
					})
					.done(function(res){
						if(res.code === '-2'){
							//验证码错误
							$("#valiCode").next().next('.tips').html("验证码错误");
							$("#valiCode").focus();
							return false;
						}else if(res.code === '-1'){
							//没有验证码
							$("#valiCode").next().next('.tips').html("请先获取验证码");
							$("#valiCode").focus();
							return false;
						}else if(res.code === '0'){
							//如果通过，清空隐藏提示框
							$("#agreement-agree").next('.tips').html("");
							$('#apply .form-xxw').css('display','none');
							$('#apply .finish-xxw').fadeIn();
						}
					});
				}
			}
		});
	}
}

function valiCode(){
	var wait = 60;
	var valiBtn = $('#getCode');
	var testMobile = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/;
	if(testMobile.test($("#mobile").val())){
		$.ajax({
			url:'/api/utility/apply',
			type: 'POST',
			data: {
				action:'verify_mobile',
				mobile:$("#mobile").val(),
			}
		})
		.done(function(res){
			if(res.code === '-2'){
				$('#mobile').next('.tips').html('您的手机号码已被注册');
				return false;
			}else if(res.code === '0'){
				valiBtn.attr("disabled", true);
				getValidCode(valiBtn, wait);
				jQuery.ajax({
					url:'/api/utility/apply',
					type: 'POST',
					dataType: "json",
					data:{
						action : 'send_vcode',
						mobile : $("#mobile").val(),
					},
				});
			}
		});
	}else if($("#mobile").val()===""){
		//如果手机输入框为空
		$("#mobile").next('.tips').html("请输入手机号码");
		$("#mobile").focus();
	}else{
		//如果不是正确的手机号
		$("#mobile").next('.tips').html("请输入正确的手机号码");
		$("#mobile").focus();
	}
}
/******* 验证  end  ****/
$(function(){
	var info = location.search.substring(1).split('&');   //ID获取
	var infoIds = {};
	for(var x in info){
		infoIds[info[x].split('=')[0]]=info[x].split('=')[1];
	}
	$('#mobile').val(infoIds.mobile);
	//申请提交
	$('#apply').on('click','.apply-button',function(){
		$('#apply .form-xxw').css('display','block');
		$('#apply .finish-xxw').css('display','none');
	});

	$('#apply .apply-now .finish-xxw .finish-btn').click(function(){
		$('#apply .apply-now .form-xxw .form input').val('');
		$('#apply .apply-now .form-xxw .form .tips').text('');
		$('#apply .input-box input').val('')
		window.location.href=location.protocol+'//'+location.host+'/';
	});

	$('#agreement-agree').click(function(){
		if($('#agreement-agree').is(':checked')){
			$('#agreement-agree').next().next().text('');
		}
	});

	$('#r_name').blur(function(){
		nameBlur();
	});
	$('#company').blur(function(){
		company();
	});
	$('#c_address').blur(function(){
		companyAddress();
	});
	$('#mobile').blur(function(){
		mobileBlur();
	});

	$('#valiCode').blur(function(){
		codeBlur();
	});
	$('#getCode').click(function(){
		valiCode();
	});
	$('#apply .apply-finish').click(function(){
		applySubmit();
	});

	//用户协议操作
	$('#agreement-agree').next().find('a').click(function(){
		$('#apply .user-agreement-bg').fadeIn();
		$('#apply .user-agreement').fadeIn();
	});
	$('#apply .user-agreement .close,#apply .user-agreement-bg').click(function(){
		$('#apply .user-agreement-bg').fadeOut();
		$('#apply .user-agreement').fadeOut();
	});
	$('#apply .user-agreement .read-agreement-btn .dis').click(function(){
		$('#apply .user-agreement-bg').fadeOut();
		$('#apply .user-agreement').fadeOut();
		$("#apply .apply-now .form-xxw .agree input[type='checkbox']").each(function(){
			this.checked=false;
		});
	});
	$('#apply .user-agreement .read-agreement-btn .agree').click(function(){
		$('#apply .user-agreement-bg').fadeOut();
		$('#apply .user-agreement').fadeOut();
		$("#apply .apply-now .form-xxw .agree input[type='checkbox']").each(function(){
			this.checked=true;
		});
	});

	var testMobile = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/;

	$('.apply-input-phone').each(function(i){
		$(this).keypress(function (event) {
			var eventObj = event || e;
			var keyCode = eventObj.keyCode || eventObj.which;
			if((keyCode >=48 && keyCode <=57))
				return true;
			else
				return false;
		});
		$('.apply-input-phone').eq(i).next('.apply-btn').click(function(){
			if(testMobile.test($('.apply-input-phone').eq(i).val())){
				window.location.href=location.protocol+'//'+location.host+'/apply?mobile='+$('.apply-input-phone').eq(i).val();
			}else{
				window.location.href=location.protocol+'//'+location.host+'/apply';
			}
		});
	})

})
